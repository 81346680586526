// src/locales/en-US.js
// src/locales/zh-CN.js
export default {
  nav: {
    home: 'home'
  },
  banner: {
    title: 'Customization develop service',
    desc: 'Let technology easily assist each enterprise, to create characteristic information services',
  },
  Teams11: {
    title: "Products & Services",
    tab1: 'Wechat develop',
    tab1desc: 'Professional customized development to meet a wide range of business needs, have successful cases.',
    tab2: 'Website develop',
    tab2desc: 'Focus on high-quality high-end website construction of development experience.',
    tab3: 'Company Technical support',
    tab3desc: 'For small and micro enterprises, to provide professional information services, reduce the cost of employment, enjoy professional services.',

  },
  aboutUs: {
    title: 'About us',
    desc1: 'We are an elite technical team of more than 30 people， the sources include former colleagues, technical salon friends, partners, etc., and the types include product manager, front and back end developer, testing, art design, etc, can provide professional technical support.',
    desc2: 'After years of technical accumulation and precipitation, we can provide professional in-depth customized development services in e-commerce, social networking, education and training, finance, medical and other fields.'
  },
  power: {
    title: "To provide you with professional services",
    desc: "Based on our strong technical capabilities",
    tab1: "One-stop service",
    tab1desc: "Provide Internet product whole process service support, enjoy information service.",
    tab2: "Enterprise Resource Management",
    tab2desc: "Can be developed to manage the company's key business functions, optimize the enterprise process, provide visual data index analysis, etc.",
    tab3: "weChat/APP",
    tab3desc: "Safe, reliable, high availability and high performance service capability that meets finance and requirements.",
  },
  team: {
    title: 'Team members',
    name1: 'qi',
    desc1:'ByteDance 2-1  The designer',
    descinfo1: '7 years of Internet design experience, rich experience in e-commerce, perfect to meet the needs of Party A.',
    name2: 'zhiDao',
    desc2:'Senior developer',
    descinfo2: '6 years of work experience, led the team to lead the development of complex applications from 0 to 1 for many times.',
  },
  footer: {
    tab1:"Development platform",
    des1: 'weChat develop',
    concat: 'Contact us',
    tel: '+86: 17342051546'
  },
};
