// src/locales/zh-CN.js
export default {
  nav: {
    home: '首页'
  },
  banner: {
    title: '定制化开发支持',
    desc: '让技术轻松助力每一家企业，打造特色信息化服务',
  },
  Teams11: {
    title: "产品与服务",
    tab1: '小程序定制/公众号',
    tab1desc: '专业定制开发，满足广泛的业务需求，多个成功案例。',
    tab2: '网站建设',
    tab2desc: '专注品质化高端网站构建，涵盖电商、HIS医疗、ERP系统等多领域开发经验。',
    tab3: '企业维护',
    tab3desc: '针对小微企业，提供专业的信息化服务，降低用人成本，享受专业服务。',

  },
  aboutUs: {
    title: '关于我们',
    desc1: '我们是一只 30余人的精尖技术团队，不乏大厂（字节、阿里、网易）工作的成员。来源有前同事、技术沙龙交友、合作伙伴等，类型上涵盖了产品经理、前后端研发、测试、美术设计等，可提供专业的 IT 技术支持。',
    desc2: '经过多年的技术积累沉淀，我们在电商、社交、教育培训、金融、医疗等领域都能提供专业的深度定制开发服务。'
  },
  power: {
    title: "为您提供专业的服务",
    desc: "基于我们强大的技术能力",
    tab1: "一站式服务",
    tab1desc: "提供互联网产品全流程服务支持，享受信息化服务。",
    tab2: "企业资源管理",
    tab2desc: "可开发用于管理公司关键业务功能，优化企业流程，提供可视化数据指标分析等。",
    tab3: "小程序/公众号/APP",
    tab3desc: "符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。",
  },
  team: {
    title: '团队成员',
    name1: '小七',
    desc1:'字节跳动 2-1&nbsp; 设计',
    descinfo1: '7年互联网设计经验，电商方面经验丰富，完美实现甲方需求。',
    name2: '志道',
    desc2:'杭州 P7',
    descinfo2: '6年工作经验，带领团队多次主导从0到1的复杂应用开发。',
  },
  footer: {
    tab1:"开发平台",
    des1: '各类小程序',
    concat: '联系我们',
    tel: '17342051546'
  },
};
