import React from 'react';
import langZH from '../locales/zh-CN';
import langEN from '../locales/en-US';
// import { isBrowser } from 'umi';
const getLocale = ()=>{
  // if (isBrowser()) {
    const lang = window.localStorage.getItem('lang');
    const langMap = {
      zh: langZH,
      en: langEN
    }
    return langMap[lang] || langZH;
  // }
  // return langZH;
}

const lang = getLocale()

export const Nav31DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo l2haxth5l8r-editor_css',
    children: 'https://cdn.2guliang.top/uPic/JPspMg.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: lang.nav.home,
              name: 'text',
              className: 'l2hb6hxum6m-editor_css',
            }
          ],
        },
      },
      // {
      //   name: 'item1',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [
      //       {
      //         children: '导航二',
      //         name: 'text',
      //         className: 'l2hb6hxum6m-editor_css',
      //       },
      //     ],
      //   },
      // },
      // {
      //   name: 'item2',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [{ children: '导航三', name: 'text' }],
      //     className: 'l2hb6o82wj-editor_css',
      //   },
      // },
      // {
      //   name: 'item3',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [
      //       {
      //         children: '导航四',
      //         name: 'text',
      //         className: 'l2hb6q5lk5j-editor_css',
      //       },
      //     ],
      //   },
      // },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner2-title l2h9j7mtaqn-editor_css',
          children: (
            <span>
              <p>{lang.banner.title}</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content l2h9md9aze-editor_css',
          children: (
            <span>
              <p>{lang.banner.desc}</p>
            </span>
          ),
        },
        button: {
          className: 'banner2-button ant-btn-ghost l2h9mfx9fdq-editor_css',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Teams11DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>{lang.Teams11.title}</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://cdn.2guliang.top/uPic/zJQ497.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>{lang.Teams11.tab1}</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>{lang.Teams11.tab1desc}</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://cdn.2guliang.top/uPic/752qYt.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>{lang.Teams11.tab2}</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                  {lang.Teams11.tab2desc}
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://cdn.2guliang.top/uPic/olQSPG.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>{lang.Teams11.tab3}</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                  {lang.Teams11.tab3desc}
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://cdn.2guliang.top/uPic/1QKq0h.jpg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>{lang.aboutUs.title}</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <p>
            {lang.aboutUs.desc1}
            </p>
            <p>
              {lang.aboutUs.desc2}<br />
            </p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>    {lang.power.title}</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>    {lang.power.desc}</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children: 'https://cdn.2guliang.top/uPic/3u4SGZ.jpg',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://cdn.2guliang.top/uPic/I5LuxP.jpg',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>    {lang.power.tab1}</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>    {lang.power.tab1desc}</p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://cdn.2guliang.top/uPic/mipkFp.jpg',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>    {lang.power.tab2}</p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  {lang.power.tab2desc}
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://cdn.2guliang.top/uPic/eLeTSh.jpg',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>{lang.power.tab3}</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: lang.power.tab3desc,
        },
      },
    ],
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: lang.team.title }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://cdn.2guliang.top/uPic/jveKdl.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>{lang.team.name1}</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>{lang.team.desc1}</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>{lang.team.descinfo1}</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://cdn.2guliang.top/uPic/pJzgJH.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>{lang.team.name2}</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <span>
                    <p>{lang.team.desc2}</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>{lang.team.descinfo2}</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo l2ha12x96fm-editor_css',
          children:
            '',
          // children:
          //   'https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: '让技术轻松助力每一家企业，打造特色信息化服务',
              className: 'l2ha18qjrio-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>{lang.footer.tab1}</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '#',
              children: (
                <span>
                  <span>
                    <p>WEB PC</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '#',
              children: (
                <span>
                  <p>
                    iOS/Android<br />
                  </p>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <span>
                    <p>{lang.footer.des1}</p>
                  </span>
                </span>
              ),
            }
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>{lang.footer.concat}</p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <span>
                    <p> 17342051546</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: 'https://cdn.2guliang.top/uPic/1851651046110_.pic.jpg',
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: 'Design',
              className: 'l2ha8ep2lmd-editor_css',
            }
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>©2022&nbsp;&nbsp;All Rights Reserved</span>
        <span>&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" rel="noopener" target="_blank">浙ICP备2021001769号-1</a></span>
      </span>
    ),
  },
};
